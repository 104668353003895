<template>
  <section class="containerSortMenu" v-click-away="clickOutside">
    <section class="containerSortMenu__header" @click="toogleMenu">
      <span class="containerSortMenu__header--label">{{store.state.selectedSort.title}}</span>
       <span class="material-icons containerSortMenu__header--icon">arrow_drop_down</span>
    </section>
    <transition
      enter-active-class="menuAnimationIn"
      leave-active-class="menuAnimationOut"
    >
      <section class="containerSortMenu__listContent" v-if="sortMenuOpen">
        <ul class="containerSortMenu__listContent--list">
          <li class="item" v-for="(item, index) in messages.sorts" :key="index" @click="selectSort(item, true)" v-show="item.title !== store.state.selectedSort.title">
            <span class="item__label">{{item.title}}</span>
          </li>
        </ul>
      </section>
    </transition>
  </section>
</template>

<script>
import sortMenu from './sortMenu.json'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

export default {
  name: 'sortMenu',
  emits: ['loadMyUploads'],
  setup (props, { emit }) {
    const store = useStore()
    const messages = sortMenu
    const sortMenuOpen = ref(false)

    /**
      * @description Show the phone icon on mobile and the Teams icon on desktop
      */
    const phoneIcon = computed(() => {
      return store.state.isMobile ? 'dialPhone' : 'msteams'
    })

    /**
     * @description Show or hide menu
     */
    function toogleMenu () {
      sortMenuOpen.value = !sortMenuOpen.value
    }

    /**
     * @description Hide menu whenever user click outside of component
     */
    function clickOutside () {
      sortMenuOpen.value = false
    }

    /**
     * @description Set the app selected by the user
     * @param item Option selected by user (JSON)
     */
    function selectSort (item) {
      store.commit({
        type: 'SET_SELECTED_SORT',
        selectedSort: item
      })
      emit('loadMyUploads')
      toogleMenu()
    }

    return {
      store,
      messages,
      sortMenuOpen,
      toogleMenu,
      clickOutside,
      selectSort,
      phoneIcon
    }
  }
}
</script>
