<template>
  <div class="skeletonMyUploadsContainer">
    <div class="skeletonMyUploadsContainer__grid">
      <div class="skeletonMyUploadsContainer__grid--item" v-for="index in itemIterations" :key="index">
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'skeletonMyUploadsPage',
  setup () {
    const itemIterations = ref(8)

    return {
      itemIterations
    }
  }
}
</script>
