<template>
  <section class="containerMyUploads">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section class="containerMyUploads__content">
      <div class="containerMyUploads__content--title">
        <h1 class="label">UPLOADED FILES</h1>
        <button class="containerMyUploads__content--uploadButton" @click="goToUploadPage">
          <span class="icon-grey-upload uploadButton"></span>
          <span class="uploadLabel">UPLOAD</span>
        </button>
      </div>
      <section class="containerMyUploads__content--sortMenu">
        <div class="showResults">
          <span class="showResults__label">Results from </span>
        </div>
        <sortMenu @loadMyUploads="loadMyUploads" />
      </section>
      <section v-if="!loadingMyUploads" class="containerMyUploads__content--cards" :class="{'noUploadsItems': !loadingMyUploads && !myUploads.length}">
        <section class="noItemsMessage" v-if="!loadingMyUploads && !myUploads.length">
          <span class="noItemsMessage__label">{{messages.noItemsMessage}}</span>
        </section>
        <ul class="list">
          <li class="list__item"
            v-for="(item, index) in myUploads" :key="index"
            :style="myUploadCardStyles(item)"
          >
            <span class="grey-icon-videocamera" v-if="isVideoType(item)"></span>
            <a class="list__item--additionalInfoContainer"
              :href="getDetailURL(item.source, item.itemId, item.internal_category).baseUrl"
              @click.prevent="goToDetail(item.source, item.itemId, index, item.internal_category)"
              :class="{'visible': indexOver === index}"
              @mouseover="indexOver = index"
              @mouseleave="resetValues">
              <header class="header">
                <span class="header__source">{{ sourceName(item.source, item.internal_category) }}</span>
                <span class="header__title box-vertical">{{item.title}}</span>
              </header>
              <section class="socialInfo">
                <span class="grey-icon-heart socialInfo__icon"
                  v-if="messages.socialToShow[item.source].likes">
                  <span class="socialInfo__icon--counter">{{ isNumberNull(item.likes) }}</span>
                </span>
                <span class="grey-icon-views socialInfo__icon"
                  v-if="messages.socialToShow[item.source].views">
                  <span class="socialInfo__icon--counter">{{ isNumberNull(item.views) }}</span>
                </span>
                <span class="grey-icon-comments socialInfo__icon"
                  v-if="messages.socialToShow[item.source].comments">
                  <span class="socialInfo__icon--counter">{{ isNumberNull(item.comments) }}</span>
                </span>
              </section>
            </a>
          </li>
        </ul>
      </section>
      <skeletonMyUploadsPage v-else></skeletonMyUploadsPage>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import messagesConfig from './myUploads.json'
import sortMenu from '@/components/sortMenu/sortMenu'
import skeletonMyUploadsPage from '@/components/skeletons/skeletonMyUploadsPage/skeletonMyUploadsPage'
import { computed, onMounted, ref } from 'vue'
import $ from 'jquery'

export default {
  name: 'myUploads',
  components: {
    sortMenu,
    skeletonMyUploadsPage
  },
  setup (props) {
    const store = useStore()
    const messages = ref(messagesConfig)
    const currentCard = ref(null)
    const indexOver = ref(null)
    const loadingMyUploads = ref(false)
    const myUploads = ref([])
    const scrollY = ref(0)

    onMounted(() => {
      loadMyUploads()
      setBackToTopButton()
    })

    /**
     * @description Set card backgroun image
     */
    const myUploadCardStyles = computed(() => {
      return item => `--background-image: url("${item.thumbnail_url}")`
    })

    /**
     * @description returns a flag to know if the item is a video.
     */
    const isVideoType = computed(() => {
      return item => item.file_type && messages.value.videoFileTypes.some(fileType => fileType === item.file_type.toLowerCase())
    })

    /**
     * @description Set a default counter if it is being null
     * @param value Counter
     */
    const isNumberNull = computed(() => (value) => {
      return value === null ? 0 : value
    })

    /**
     * @description Re name source to show correct card title
     * @param source Card soruce
     * @param type The type of gogrey item
     */
    const sourceName = computed(() => (source, type) => {
      if (source === 'gogrey') {
        switch (type) {
          case 'Article':
            return 'Go Grey News'
          case 'Opportunity':
            return 'Borderless Opportunities'
          case 'Highlight':
            return 'Highlight'
          case 'Spotlight':
            return 'Office Spotlight'
        }
      } else return source
    })

    /**
     * @description gets main container to be able to listen to its scroll
     */
    function setBackToTopButton () {
      const mainSpace = document.querySelector('#containerApp')
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    /**
     * @description scrolls the page to the top
     */
    function scrollToTop () {
      $('html, #containerApp').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description Returns the detailUrl
     * @param source of app
     * @param cardId Id of card
     * @param itemType The type of gogrey item
     */
    function getDetailURL (source, cardId, itemType) {
      let baseUrl = ''
      let detailPage = ''
      const env = process.env.VUE_APP_API_ENV === 'prd' ? '' : `${process.env.VUE_APP_API_ENV}-`
      switch (source) {
        case 'news':
          detailPage = `detailNews/${cardId}`
          break
        case 'intelligence':
          source = 'search'
          detailPage = `detail/${cardId}`
          break
        case 'work':
          source = 'search'
          detailPage = `detailWork/${cardId}`
          break
        case 'culturedaily':
          detailPage = myUploads.value.find(item => item.itemId === cardId).src_url
          break
        case 'gogrey':
          source = 'go'
          switch (itemType) {
            case 'Article':
              source = 'news'
              detailPage = `detailNews/${cardId}`
              break
            case 'Opportunity':
              detailPage = `detailOpportunity/${cardId}`
              break
            case 'Spotlight':
              detailPage = `detailSpotlight/${cardId}`
              break
            case 'Highlight':
              detailPage = `?highlightId=${cardId}&origin=uploadedFiles`
          }
          break
      }
      baseUrl = source === 'culturedaily' ? detailPage : `https://${env}${source}.grey.com/${detailPage}`
      return {
        baseUrl: baseUrl,
        targetLink: source === 'culturedaily' ? '_blank' : '_self'
      }
    }

    /**
     * @description Load my uploads list items.
     */
    function loadMyUploads () {
      loadingMyUploads.value = true
      myUploads.value = []
      store.dispatch({
        type: 'getUploadsList'
      }).then(
        (response) => {
          myUploads.value = response.data
          loadingMyUploads.value = false
        }
      )
    }

    /**
     * @description Go to detail page from the card
     * @param source of app
     * @param cardId Id of card to redirect to the detail
     * @param index of item
     * @param itemType The type of gogrey item
     */
    function goToDetail (source, cardId, index, itemType) {
      const baseUrl = getDetailURL(source, cardId, itemType)
      if (navigator.userAgent.match(/Android|iPhone|iPad/i)) {
        if (currentCard.value !== index) {
          currentCard.value = index
          return
        }
      }
      window.open(baseUrl.baseUrl, baseUrl.targetLink)
    }

    /**
     * @description Reset variable to initial state when leave event has been launched
     */
    function resetValues () {
      currentCard.value = null
      indexOver.value = null
    }

    /**
     * @description redirect to UploadFile page
     */
    function goToUploadPage () {
      const env = process.env.VUE_APP_API_ENV === 'prd' ? '' : `${process.env.VUE_APP_API_ENV}-`
      const baseUrl = `https://${env}search.grey.com/uploadFile`
      window.open(baseUrl, '_self')
    }

    return {
      store,
      myUploadCardStyles,
      isVideoType,
      isNumberNull,
      goToDetail,
      resetValues,
      loadMyUploads,
      loadingMyUploads,
      myUploads,
      messages,
      indexOver,
      goToUploadPage,
      getDetailURL,
      sourceName,
      scrollToTop,
      scrollY
    }
  }
}
</script>
